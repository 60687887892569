import { template as template_6f1015331ee541309ed10885c28701dc } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { concat } from "@ember/helper";
import { action } from "@ember/object";
import { isBlank } from "@ember/utils";
import UppyImageUploader from "discourse/components/uppy-image-uploader";
export default class FKControlImage extends Component {
    static controlType = "image";
    @action
    setImage(upload) {
        this.args.field.set(upload);
    }
    @action
    removeImage() {
        this.setImage(undefined);
    }
    get imageUrl() {
        return isBlank(this.args.value) ? null : this.args.value;
    }
    static{
        template_6f1015331ee541309ed10885c28701dc(`
    <UppyImageUploader
      @id={{concat @field.id "-" @field.name}}
      @imageUrl={{this.imageUrl}}
      @onUploadDone={{this.setImage}}
      @onUploadDeleted={{this.removeImage}}
      @type={{@type}}
      class="form-kit__control-image no-repeat contain-image"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
