import { template as template_19d901e5498a41aab378f670c95b3d61 } from "@ember/template-compiler";
const FKLabel = template_19d901e5498a41aab378f670c95b3d61(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
