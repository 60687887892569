import { template as template_85b09189a3e04e9bae5b40a9a81c3f16 } from "@ember/template-compiler";
const SidebarSectionMessage = template_85b09189a3e04e9bae5b40a9a81c3f16(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
